import { BehaviorSubject, catchError, defer, delay, from, map, of, repeat, repeatWhen, share, tap } from "rxjs";
import axios from "axios";

const params = new URLSearchParams(window.location.search);
const paramElection = params.get("election");
const API_URL = paramElection
    ? process.env.REACT_APP_URL_SERVER + "?election=" + paramElection
    : process.env.REACT_APP_URL_SERVER;

console.log("APIURL", API_URL);
//SUBJECTS
export const callFetch$ = defer(() => from(fetchBureauxAPI())).pipe(
    tap((d) => bureaux.next(d.data.bureaux || [])),
    repeat({ delay: 60000 }),
    // share(),
    catchError((err) => console.error("ERROR", err))
);
const bureaux = new BehaviorSubject([]);
//OBSERVABLES
export const bureaux$ = bureaux.asObservable();
export const countBureaux$ = bureaux$.pipe(
    tap((bureaux) => console.log("bureaux: , ", bureaux)),
    map((bureaux) => {
        return {
            arrive: bureaux.filter((b) => b.etat === "arrive").length,
            attente: bureaux.filter((b) => b.etat === "nonarrive").length,
            erreur: bureaux.filter((b) => b.etat === "erreur").length,
            arriveMairie: bureaux.filter((b) => b.urne_arrivee > 0).length,
            total: bureaux.length,
        };
    }),
    tap((obj) => console.log("obj: , ", obj))
);

export function fetchBureauxAPI() {
    console.log("%c - CALL fetchBureauxAPI() -", "color: orange");
    return axios({
        method: "get",
        url: `${API_URL}`,
        headers: { "content-type": "application/json" },
    });
}

export const patchBureau = (bureau, status) => {
    axios({
        method: "POST",
        url: `${API_URL}`,
        headers: { "content-type": "application/json" },
        data: { bureau, urne_arrivee: status },
    })
        .then((res) => {
            console.log("res.data", res.data);
            const b = res.data.data;
            const newB = bureaux.value.map((el) => (el.bureau == bureau ? b : el));
            bureaux.next(newB);
        })
        .catch((err) => {
            return new Error(err);
        });
};
