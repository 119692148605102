import React, { useEffect, useState } from "react";
import { bureaux$, countBureaux$ } from "./store";

export const Header = ({election: {label, tour, sousType, annee}, ...props}) => {

   const [stats, setStats] = useState({
      arrive: 0,
      attente: 0,
      erreur: 0,
      arriveMairie: 0,
      total: 0
   })
   useEffect(() => {
      
      const subs = []
      // subs.push(bureaux$.subscribe(()));
      subs.push(countBureaux$.subscribe(setStats));
      return () => {
         subs.foreach(sub => sub.unsubscribe());
      }
   }, [])


   return (
      <div className="header">
         <h1>{label} {annee} - {tour} {sousType && "- "+ sousType}</h1>
         <div className="info">
            <ul>
               <li>
                  <img src="./imgs/icon-waiting.png" />
                  {stats.attente}
               </li>
               <li>
                  <img src="./imgs/icon-error.png" />
                  {stats.erreur}
               </li>
               <li>
                  <img src="./imgs/phone.png" />
                  {stats.arrive} / {stats.total}
               </li>
               <li>
                  <img src="./imgs/flag.png" style={{padding: "4px"}} />
                  {stats.arriveMairie} / {stats.arrive}
               </li>
            </ul>
         </div>
      </div>
   );
};
