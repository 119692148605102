import "./App.css";

import React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Bureaux } from "./Bureau";
import { Header } from "./Header";
import { getTypeElection } from "./utils/getTypeElection";
import { bureaux$, callFetch, callFetch$, fetchBureauxAPI } from "./store";

const App = () => {
   console.log("%cApp: render start", "color: MediumSpringGreen");

   const [params, setParams] = useSearchParams();
   const paramElection = params.get("election");

   const API_URL = paramElection ? process.env.REACT_APP_URL_SERVER + paramElection : process.env.REACT_APP_URL_SERVER;
   // const [bureaux, setBureaux] = React.useState([]);
   const [election, setElection] = React.useState({});

   const [bureaux, setBureaux] = React.useState([]);


   React.useEffect(() => {
      console.log("%cApp: useEffect(() => {}, [])", "color: LightCoral");
      // fetchBureaux()
      // fetchBureauxAPI();

      const subs = []
      subs.push(callFetch$.subscribe());
      subs.push(bureaux$.subscribe((d)=> {
         if(d.length > 0) {
            setBureaux(d)
            setElection(getTypeElection(d[0].election))
         } 
      }));
      return () => {
         console.log("%cApp: useEffect(() => {}, []) cleanup 🧹", "color: LightCoral");
         console.log('SUBS', subs)
         subs.forEach(sub => sub.unsubscribe());
      };
   }, []);

   React.useEffect(() => {
      console.log("%cApp: useEffect(() => {}, [bureauRX])", "color: LightBlue");
      return () => {
         console.log("%cApp: useEffect(() => {}, [bureauRX]) cleanup 🧹", "color: LightBlue");
      };
   }, [bureaux]);


   
   const setBureau = (bureau, status) => {
      console.log('%c SET BUREAU', 'color: grey')
      axios({
         method: "POST",
         url: `${API_URL}`,
         headers: { "content-type": "application/json" },
         data: { bureau, urne_arrivee: status },
      })
         .then((res) => {
            console.log("res.data", res.data);
            const b = res.data.data;
            const newB = bureaux.map((el) => (el.bureau == bureau ? b : el));
            setBureaux(newB);
         })
         .catch((err) => {
            return new Error(err);
         });
   };
   const generateBureau = () => {
      return bureaux.map((el, index) => <Bureaux onSetBureau={setBureau} key={el.bureau} numero={el.bureau} {...el} />);
   };

   console.log("%cApp: render end", "color: MediumSpringGreen");
   return (
      <div className="app">
         <Header election={election} />
         <div className="container--bureaux">{generateBureau()}</div>
      </div>
   );
};

export default App;
