export const getTypeElection = (codeElection) => {
   const [election, typeElection, anneeElection, tourElection, sousTypeElection] = codeElection.match(/([A-Z]{3})(\d{2})-(\d)([C-D]?)/);
   console.log(typeElection);
   let label = "";
   let tour = ""
   let sousType ="";
   let annee = "20"+anneeElection;
   switch (typeElection) {
       case "REF":
           label = "Référendum";
           break;
       case "EUR":
           label = "Européenne";
           break;
       case "LEG":
           label = "Législatives";
           break;
       case "CAN":
           label = "Cantonale";
           break;
       default:
           label = "Législatives";
   }

   //TOUR ELECTION
   if(tourElection == 1) tour = "1er tour"
   if(tourElection == 2) tour = "2nd tour"


   //SOUS TYPE
   switch (sousTypeElection){
      case "C":
         sousType = "1er centaine";
         break;
      case "D":
         sousType = "2ème centaine"

      default:
         sousType = "";
   }

   return {
      label: label.toUpperCase(),
      tour :  tour.toUpperCase(),
      sousType: sousType.toUpperCase(),
      annee
   }
};
