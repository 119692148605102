import React, { useRef } from "react";
import Popup from "reactjs-popup";
import { patchBureau } from "./store";
import cx from "classnames";

export const Bureaux = ({ numero, label, etat, inscrit, onSetBureau, urne_arrivee, ...props }) => {
   const ref = useRef();
   return (
      <Popup
         ref={ref}
         trigger={(open) => (
            <div
               className={cx('box',{
                  [`box--${etat}`]: urne_arrivee != 1,
                  'box--arrive-mairie': urne_arrivee == 1 && etat == "arrive"
               })}>
               <div className="box--numero">{numero}</div>
               {etat == "arrive"  ?  <img className="box--icon" src={`imgs/${urne_arrivee > 0 ? "flag" : "phone"}.png`}/> : null }
            </div>
         )}
         position={["right center", "left center"]}
         keepTooltipInside={true}
         closeOnDocumentClick>
         <PopupCustom ref={ref} label={label} numero={numero} inscrit={inscrit} setBureau={onSetBureau} etat={etat} urne_arrivee={urne_arrivee} {...props} />
      </Popup>
   );
};

const PopupCustom = React.forwardRef(({ label, setBureau, inscrit, numero, urne_arrivee, etat, ...props }, ref) => {
   console.log("%c    POPUP: render start", "color: yellow");
   React.useEffect(() => {
      console.log("%c    POPUP: useEffect(() => {})", "color: orange");
      return () => {
         console.log("%c    POPUP: useEffect(() => {}) cleanup 🧹", "color: orange");
      };
   });

   const handleClick = (status) => {
      patchBureau(numero, status);
      ref.current.close();
   };

   const renderActions = () => {
      console.log("%c    ETAT", "color: yellow", etat, urne_arrivee);
      if (etat != "erreur" && etat != "nonarrive") {
         if (urne_arrivee == 0)
            return (
               <button className="btn btn--icon" onClick={() => handleClick(1)}>
                  <img className="popup--icon" src="imgs/flag.png" alt="arriver" />
               </button>
            );
         if (urne_arrivee > 0)
            return (
               <button className="btn btn--icon" onClick={() => handleClick(0)}>
                  <img className="popup--icon" src="imgs/cross.png" alt="arriver" />
               </button>
            );
      } else {
         if (etat == "erreur") return <h3>- ERREUR -</h3>;
         if (etat == "nonarrive") return <h3>Le resultat n'a pas été transmis</h3>;
      }
   };

   return (
      <div className="container--popup">
         <h3>{label}</h3>
         <hr />
         <ul>
            <li>Inscrits: {inscrit}</li>
            <li>Votants: {props.votant}</li>
            <li>Exprimés: {props.exprime}</li>
            <li>Nuls: {props.nul}</li>
            <li>Blanc: {props.blanc}</li>
         </ul>
         <hr />
         <div className="actions">{renderActions()}</div>
      </div>
   );
});
